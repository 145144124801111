<template>
  <h2>{{ tool.name }}</h2>
  <div class="row justify-center">
    <div class="column">
      <b class="row justify-center" id="input-label">
        <pill :text="tool.input.type"/> &nbsp;Input
      </b>
      <textarea id="input-editor"
                v-model="input"
                @keyup="updateOutput()"
                aria-labelledby="input-label">
      </textarea>
    </div>
    <div class="column">
      <b class="row justify-center" id="output-label">
        <pill :text="tool.output.type"/> &nbsp;Output
      </b>
      <textarea v-model="output" aria-labelledby="output-label"></textarea>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType, ref, watch} from 'vue';
import {Tool} from "@/models/tool";
import ComputeService from "@/services/compute_service";
import Pill from "@/components/tool_card/Pill.vue";

export default defineComponent({
  props: {
    tool: Object as PropType<Tool>
  },
  components: {
    Pill
  },
  setup(props) {
    const input = ref('');
    const output = ref('');

    const updateOutput = function () {
      let funcName: string = props?.tool?.id ?? '';
      try {
        output.value = ComputeService.funcMap[funcName](input.value);
      } catch (e) {
        console.log(e.message);
      }
    };

    // eslint-disable-next-line no-unused-vars
    watch(() => props.tool, (_value, _oldValue) => {
      input.value = '';
      output.value = '';
      document.getElementById('input-editor')?.focus();
    });

    return {
      input,
      output,
      updateOutput
    }
  }
})
</script>

<style lang="scss" scoped>
textarea {
  width: 264px;
  height: 180px;
  margin: 8px;
}
</style>