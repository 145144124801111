
import { defineComponent, ref } from 'vue';
import toolsJson from '@/assets/json/tools.json';
import {Tool} from "@/models/tool";
import ToolCard from "@/components/tool_card/ToolCard.vue";
import {useRouter} from "vue-router";

export default defineComponent({
  props: {
    msg: String
  },
  components: {
    ToolCard,
  },
  setup() {
    const router = useRouter();
    const toolsData = toolsJson as Array<Tool>;
    const activeTool = ref(toolsData[0]);

    const selectTool = function(tool: Tool) {
      router.push({name: 'Tool', params: {toolName: Tool.urlName(tool.name)}})
    };

    return {
      tools: toolsData,
      activeTool,

      selectTool
    }
  }
})

