<template>
  <h1><router-link to="/">Tool Plaza</router-link></h1>
  <p>The best tools on the internet to get you from A to Z instantly!</p>

  <router-view></router-view>

  <hr>

  <div class="row justify-center">
    <tool-card v-for="tool in tools"
               :tool="tool"
               :key="tool.id"
               tabindex="0">
    </tool-card>
  </div>

</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import toolsJson from '@/assets/json/tools.json';
import {Tool} from "@/models/tool";
import ToolCard from "@/components/tool_card/ToolCard.vue";
import {useRouter} from "vue-router";

export default defineComponent({
  props: {
    msg: String
  },
  components: {
    ToolCard,
  },
  setup() {
    const router = useRouter();
    const toolsData = toolsJson as Array<Tool>;
    const activeTool = ref(toolsData[0]);

    const selectTool = function(tool: Tool) {
      router.push({name: 'Tool', params: {toolName: Tool.urlName(tool.name)}})
    };

    return {
      tools: toolsData,
      activeTool,

      selectTool
    }
  }
})

</script>

<style scoped>
h3 {
  margin: 40px 0 0;
}
h1, p {
  margin: 0;
}

ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: inherit;
  text-decoration: none;
}

hr {
  background-color: #ddd;
  height: 1px;
  border: 0;
  width: 80%;

}
</style>
