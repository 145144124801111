
import {defineComponent, PropType} from 'vue';
import Pill from "@/components/tool_card/Pill.vue";
import {Tool} from "@/models/tool";

export default defineComponent({
  props: {
    tool: Object as PropType<Tool>
  },
  components: {
    Pill
  },
  setup() {
    return {
      Tool
    }
  }
})
