export default class ComputeService {
    static funcMap: Record<string, any> = {
        'stringToDecimal': ComputeService.stringToDecimal,
        'decimalToString': ComputeService.decimalToString,
        'base64Encode': ComputeService.base64Encode,
        'base64Decode': ComputeService.base64Decode,
        'jsonStringify': ComputeService.jsonStringify,
        'jsonParse': ComputeService.jsonParse,
        'evaluatePostfix': ComputeService.evaluatePostfix,
        'infixToPostfix': ComputeService.infixToPostfix,
    };

    static stringToDecimal(input: string): string {
        let output: string = '';
        output = input.split('').map((value) => value.charCodeAt(0)).join(' ');
        return output;
    }

    static decimalToString(input: string): string {
        return input
            .split('\n')
            .map((line) =>
                line
                    .split(' ')
                    .map((value) => String.fromCharCode(parseInt(value))).join('')).join('\n');
    }

    static base64Encode(input: string): string {
        return btoa(input);
    }

    static base64Decode(input: string): string {
        return atob(input);
    }

    static jsonStringify(input: string): string {
        return JSON.stringify(JSON.stringify(JSON.parse(input)));
    }

    static jsonParse(input: string): string {
        const a = JSON.parse(JSON.parse(input));
        return JSON.stringify(a, null, 2);
    }

    static evaluatePostfix(input: string): string {
        const operators = ['*', '/', '+', '-'];
        const stack: any[] = [];
        const elements = input.split(' ');

        for (let i = 0; i < elements.length; i++) {
            const element = elements[i];
            if (operators.includes(element)) {
                const b = stack.pop();
                const a = stack.pop();
                stack.push(ComputeService.operateOnOperands(a, b, element));
            } else {
                stack.push(parseFloat(element));
            }
        }

        return stack.length > 0 ? stack.pop() : 0;
    }

    /**
     * Converts an infix expression to postfix.
     *
     * eg. 1+2-3 = 1 2 + 3 -
     * @param input
     */
    static infixToPostfix(input: string): string {
        const operators = ['*', '/', '+', '-'];
        const operandStack: any[] = [];
        const stack: any[] = ['('];
        const expression: string[] = [];
        input = input + ')';

        for (let i = 0; i < input.length; i++) {
            const char = input.charAt(i);

            if (ComputeService.isNumber(char)) {
                let extractedChar = '';

                for (let j = i; j < input.length; j++) {
                    if (ComputeService.isNumber(input.charAt(j))) {
                        extractedChar += input.charAt(j);
                    } else {
                        i = j - 1;
                        break;
                    }
                }

                // Append operand to stack
                expression.push(extractedChar);
            } else if (ComputeService._isAlpha(char)) {
                expression.push(char);
            } else if (operators.includes(char)) {
                const isHigherOrder: boolean = ['*', '/'].includes(char);
                while (stack.length > 0) {
                    const top = stack[stack.length - 1];
                    if (top == '(') {
                        break;
                    }

                    // Only push
                    if ((isHigherOrder && ['*', '/'].includes(top)) || !isHigherOrder) {
                        expression.push(stack.pop());
                    } else {
                        break;
                    }
                }
                stack.push(char);
            } else if (char == '(') {
                stack.push(char);
            } else if (char == ')') {
                while (stack.length > 0) {
                    const popped = stack.pop();
                    if (popped == '(') {
                        break;
                    }
                    expression.push(popped);
                }
            }
        }


        return expression.join(' ');
    }

    private static isNumber(char: string): boolean {
        return char >= '0' && char <= '9';
    }

    private static _isAlpha(char: string): boolean {
        return char.toLowerCase() >= 'a' && char.toLowerCase() <= 'z';
    }

    private static operateOnOperands(a: number, b: number, op: string): number {
        switch (op) {
            case '+':
                return a + b;
            case '-':
                return a - b;
            case '*':
                return a * b;
            case '/':
                return a / b;
            default:
                return 0;
        }
    }

}