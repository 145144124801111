
import {defineComponent, ref} from "vue";
import {Tool} from "@/models/tool";
import TransformerEditor from "@/components/transformer_editor/TransformerEditor.vue";
import toolsJson from '@/assets/json/tools.json';
import {onBeforeRouteUpdate, RouteLocationNormalized, useRoute, useRouter} from "vue-router";


export default defineComponent({
  components: {
    TransformerEditor
  },
  metaInfo() {
    return {
      title: 'test'
    }
  },
  setup() {
    // eslint-disable-next-line no-unused-vars
    const _tools = toolsJson as Array<Tool>;
    // eslint-disable-next-line no-unused-vars
    const router = useRouter();
    const route = useRoute();
    const activeTool: any = ref<Tool>();


    const updateTool = (route: RouteLocationNormalized): void => {
      const findTool =
          _tools.find((tool) => tool.name.toLowerCase().replaceAll(' ', '-') == route.params.toolName);
      if (findTool != undefined) {
        document.title = `Tool Plaza | ${findTool.name}`;
        document
            .querySelectorAll('meta[name$="description"]')
            .forEach(
                (el) => el.setAttribute('content', `Use our tool to help you with ${findTool.name}.`))

        activeTool.value = findTool;

      } else {
        console.log(route.params.toolName);
      }
    };

    updateTool(route);


    // eslint-disable-next-line no-unused-vars
    onBeforeRouteUpdate(async (to, from) => {
      updateTool(to);
    });

    return {
      activeTool
    }
  }
})
