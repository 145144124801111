import {createRouter, createWebHistory} from "vue-router";
import EditorPage from "@/components/editor_page/EditorPage.vue";
import HomePage from "@/components/home_page/HomePage.vue";

const routes = [
    {
        path: "/",
        name: "Home",
        component: HomePage,
    },
    {
        path: "/tools/:toolName",
        name: "Tool",
        component: EditorPage,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;