export enum Type {
    STRING = 'String',
    DECIMAL = 'Decimal',
    BASE_64 = 'Base64',
    JSON = 'JSON'
}

/**
 * Defines information for a single tool that converts some input to some output.
 */
export class Tool {
    id: string;
    name: string;
    input: ToolType;
    output: ToolType;

    constructor({id, name, input, output}: { id: string, name: string, input: ToolType, output: ToolType }) {
        this.id = id;
        this.name = name;
        this.input = input;
        this.output = output;
    }

    static urlName(name: string): string {
        return name.replaceAll(' ', '-').toLowerCase();
    }
}

export class ToolType {
    name?: string;
    type: Type;

    constructor({name, type}: { name?: string, type: Type }) {
        this.name = name;
        this.type = type;
    }
}