
import {defineComponent, PropType, ref, watch} from 'vue';
import {Tool} from "@/models/tool";
import ComputeService from "@/services/compute_service";
import Pill from "@/components/tool_card/Pill.vue";

export default defineComponent({
  props: {
    tool: Object as PropType<Tool>
  },
  components: {
    Pill
  },
  setup(props) {
    const input = ref('');
    const output = ref('');

    const updateOutput = function () {
      let funcName: string = props?.tool?.id ?? '';
      try {
        output.value = ComputeService.funcMap[funcName](input.value);
      } catch (e) {
        console.log(e.message);
      }
    };

    // eslint-disable-next-line no-unused-vars
    watch(() => props.tool, (_value, _oldValue) => {
      input.value = '';
      output.value = '';
      document.getElementById('input-editor')?.focus();
    });

    return {
      input,
      output,
      updateOutput
    }
  }
})
