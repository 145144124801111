<template>
  <div></div>
</template>

<script lang="ts">
import {defineComponent} from "vue";

export default defineComponent({
  name: "HomePage"
})
</script>

<style scoped>

</style>