<template>
  <div class="tag">{{ text }}</div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
  props: {
    text: String
  },
  setup() {

  }
})
</script>

<style lang="scss" scoped>
.tag {
  border-radius: 8px;
  padding: 0 8px;
  color: white;
  font-weight: bold;
  background-color: #c3470c;
}
</style>