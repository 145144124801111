<template>
  <router-link :to="`/tools/${Tool.urlName(tool.name)}`" class="card column">
    <h2 class="full-flex">{{tool.name}}</h2>
    <!--<p class="full-flex">Description about the card..</p>-->
    <div class="row tags justify-center">
      <pill :text="tool.input.type"></pill>
      &nbsp;
      <pill :text="tool.output.type"></pill>
    </div>
  </router-link>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import Pill from "@/components/tool_card/Pill.vue";
import {Tool} from "@/models/tool";

export default defineComponent({
  props: {
    tool: Object as PropType<Tool>
  },
  components: {
    Pill
  },
  setup() {
    return {
      Tool
    }
  }
})
</script>

<style lang="scss" scoped>
h1, h2, h3 {
  margin: 0;
}
.card {
  padding: 8px;
  margin: 8px;
  width: 240px;
  background-color: #ddd; /*#75ace4*/
  color: #444;
  cursor: pointer;

  transition: 0.25s all ease-in;

  p {
    color: #555;
  }
}

.card:hover, .card:focus {
  transform: scale(1.2);
  background-color: #81c8ea;
}

.card:active {
  background-color: white;
}

.tags {
  margin: 8px;
}
</style>